import { Button } from "../components/Button";
import { useLocation } from "wouter";
import React, { useEffect, useState } from "react";
import { handleCreateRoom } from "~/api/roomApi";
import { useSnapshot } from "valtio";
import { CartPage, loadCartIds, localState } from "~/state/state";
import { UserProfile, getUser } from "~/api/userApi";

import { StyledProfilePic } from "../components/ProfilePic";
import { imageUrl } from "~/utils/imageurl";
import { trackEvent } from "~/api/analyticsApi";
import { keyframes, styled } from "../style/stitches.config";
// import { videoAsk } from "../../utils/videoask";

const ProfileMenuItem = ({
  src,
  text,
  onClick,
}: {
  src: string;
  text: string;
  onClick: Function;
}) => {
  return (
    <StyledProfileMenuItem
      id={`profile-menu-${text}`}
      onClick={onClick as React.MouseEventHandler}
    >
      <img className="icon" src={src}></img>
      <div className="text">{text}</div>
    </StyledProfileMenuItem>
  );
};

export const TopBar = ({
  user,
  signout,
}: {
  user: UserProfile | null;
  signout: Function;
}) => {
  const [location, setLocation] = useLocation();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isInviteMenuOpen, setIsInviteMenuOpen] = useState(false);
  const [isLinkCopyOpen, setIsLinkCopyOpen] = useState(false);
  const {
    isConnecting,
    isConnected,
    inDarkMode,
    isInRoom,
    editedProfilePic,
    isOnMobile,
    isOnPlusPlan,
    currCartIds,
  } = useSnapshot(localState);

  useEffect(() => {
    loadCartIds();
  }, []);

  useEffect(() => {
    if (isProfileMenuOpen) {
      document
        .getElementById("profile-menu-Feedback")!
        .setAttribute(
          "onclick",
          "videoask.loadModal('https://www.videoask.com/fcaqxaw1l')"
        );
    }
  }, [isProfileMenuOpen]);

  const [profilePic, setProfilePic] = useState("");
  useEffect(() => {
    (async () => {
      if (!user?.uuid) return;
      getUser(user?.uuid).then((res) => {
        setProfilePic(res.profilePic);
      });
    })();
    localState.editedProfilePic = false;
  }, [editedProfilePic, user]);

  useEffect(() => {
    if (!user) setIsProfileMenuOpen(false);
  }, [user]);

  const handleLinkCopy = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setIsLinkCopyOpen(true);
      window.setTimeout(() => setIsLinkCopyOpen(false), 3000);
    });
  };

  const profileMenu = (
    <div>
      <div
        style={{ display: "flex", alignItems: "center", position: "relative" }}
      >
        {isConnected && isInRoom && (
          <>
            <div style={{ position: "relative", marginRight: "6px" }}>
              <Button
                style={{
                  height: "28px",
                  padding: "0 16px 0 12px",
                  fontSize: "12px",
                  borderRadius: "9px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: !inDarkMode
                    ? "1px solid transparent"
                    : "1px solid black",
                  margin: 0,
                }}
                animateIn
                darkHover={!inDarkMode}
                onClick={() => {
                  trackEvent("/room/invite");
                  setIsInviteMenuOpen(!isInviteMenuOpen);
                }}
              >
                <img
                  style={{
                    width: "18px",
                    height: "18px",
                    marginRight: "4px",
                    marginTop: "-1px",
                    marginLeft: 0,
                    filter: !inDarkMode ? "none" : "invert(1)",
                  }}
                  className="icon"
                  src="/images/room/invite.svg"
                ></img>
                Invite
              </Button>
              {isInviteMenuOpen && (
                <div
                  style={{
                    position: "absolute",
                    top: "50px",
                    right: "0",
                    width: "154px",
                    height: isOnMobile ? "170px" : "150px",
                    padding: "30px 16px 16px 16px",
                    borderRadius: "25px",
                    color: inDarkMode ? "white" : "black",
                    border: !inDarkMode ? "1px solid black" : "1px solid white",
                    backgroundColor: !inDarkMode ? "white" : "black",
                  }}
                >
                  <div
                    className="back-button arrow-button"
                    style={{
                      position: "absolute",
                      top: isOnMobile ? "3px" : "8px",
                      right: "10px",
                      width: "20px",
                      transform: "rotate(90deg)",
                      padding: "2px 6px",
                      fontSize: isOnMobile ? "18px" : "14px",
                      cursor: "pointer",
                      color: inDarkMode ? "white" : "black",
                    }}
                    onClick={() => setIsInviteMenuOpen(false)}
                  >
                    {" "}
                    &larr;
                  </div>
                  <div
                    className="text"
                    style={{ fontSize: isOnMobile ? "12px" : "10px" }}
                  >
                    Share this link to invite anyone into your room. Up to 6
                    people can join.
                  </div>
                  <StyledLinkInput
                    type="text"
                    onClick={handleLinkCopy}
                    className="link"
                    style={{
                      maxWidth: "100%",
                      overflow: "hidden",
                      fontSize: "10px",
                      textOverflow: "ellipsis",
                      width: "100%",
                      display: "block",
                      height: "25px",
                      marginBottom: "4px",
                      borderRadius: "32px",
                      whiteSpace: "nowrap",
                      paddingLeft: "8px",
                      marginTop: "12px",
                      cursor: "pointer",
                      backgroundColor: inDarkMode ? "black" : "white",
                      border: inDarkMode
                        ? "1px solid white"
                        : "1px solid black",
                    }}
                    value={window.location.href}
                  />

                  <Button
                    style={{
                      height: "28px",
                      width: "100%",
                      padding: "0 16px 0 12px",
                      fontSize: isOnMobile ? "12px" : "10px",
                      borderRadius: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: !inDarkMode
                        ? "1px solid white"
                        : "1px solid black",
                      color: !inDarkMode ? "white" : "black",
                      margin: 0,
                    }}
                    darkHover={!inDarkMode}
                    onClick={handleLinkCopy}
                  >
                    {!isLinkCopyOpen && (
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "4px",
                          marginTop: "-1px",
                          marginLeft: 0,
                          filter: !inDarkMode ? "none" : "invert(1)",
                        }}
                        className="icon"
                        src="/images/room/copy.svg"
                      ></img>
                    )}
                    {isLinkCopyOpen ? "Link copied!" : "Copy link"}
                  </Button>
                </div>
              )}
            </div>
            <Button
              style={{
                height: "28px",
                padding: "0 16px",
                fontSize: "12px",
                borderRadius: "9px",
                marginRight: "16px",
                border: inDarkMode ? "1px solid white" : "1px solid black",
                margin: 0,
              }}
              animateIn
              darkHover={inDarkMode}
              onClick={() => {
                trackEvent("/room/leave_button");
                setLocation("/leave");
                setIsProfileMenuOpen(false);
                localState.isInRoom = false;
              }}
            >
              Leave
            </Button>
          </>
        )}
        {!isInRoom &&
          !isConnecting &&
          !window.location.pathname.includes("video") && (
            <>
              {!window.location.pathname.includes("profile") && (
                <Button
                  id="cart-button"
                  style={{
                    height: "26px",
                    padding: "0 16px",
                    fontSize: "12px",
                    borderRadius: "9px",
                    margin: 0,
                    marginRight: "4px",
                    border: !inDarkMode
                      ? "1px solid black"
                      : "1px solid transparent",
                  }}
                  animateIn
                  onClick={() => {
                    localState.cartPage = CartPage.CART;
                  }}
                >
                  <img src="/images/cart.svg" />
                  {currCartIds.length > 0 ? `${currCartIds.length}` : ""}
                </Button>
              )}
              <Button
                id="create-room-button"
                style={{
                  height: "26px",
                  padding: "0 16px",
                  fontSize: "12px",
                  borderRadius: "9px",
                  marginRight: "16px",
                  border: inDarkMode
                    ? "1px solid black"
                    : "1px solid transparent",
                  margin: 0,
                }}
                animateIn
                darkHover={!inDarkMode}
                onClick={() => {
                  trackEvent("/topbar/create_room");
                  // get everything after the ? if there is anything
                  const extraUrl = window.location.search;
                  handleCreateRoom(extraUrl);
                  setIsProfileMenuOpen(false);
                }}
              >
                + Room
              </Button>
              <Button
                id="create-room-with-question-button"
                style={{
                  height: "0px",
                  width: "0px",
                  pointerEvents: "none",
                  padding: "0px",
                  opacity: 0,
                }}
                onClick={async () => {
                  trackEvent("/topbar/create_room_with_question");
                  await handleCreateRoom("?useQuestion=true");
                  setIsProfileMenuOpen(false);
                }}
              >
                + Room
              </Button>
            </>
          )}
        <StyledProfilePic
          css={{ $$size: "30px", $$padding: "90px" }}
          onClick={() => {
            trackEvent("/topbar/click_pfp_menu");
            setIsProfileMenuOpen(!isProfileMenuOpen);
          }}
          className="profile-pic"
          src={imageUrl(`/profile-pics/${profilePic}`, 60)}
        />
      </div>
      {isProfileMenuOpen && (
        <div className="profile-menu">
          <Button
            role="menuitem"
            className="arrow-button"
            style={{
              padding: "2px 6px",
              position: "absolute",
              right: "10px",
              top: "8px",
              transform: "rotate(90deg)",
            }}
            transparent
            onClick={() => {
              setIsProfileMenuOpen(false);
            }}
          >
            &larr;
          </Button>
          <ProfileMenuItem
            text={"Profile"}
            src={"/images/profile.svg"}
            onClick={() => {
              if (isInRoom || isConnecting)
                window.open(
                  window.location.protocol +
                    "//" +
                    window.location.host +
                    "/profile/" +
                    user?.username,
                  "_blank"
                );
              else setLocation("/profile/" + user?.username);
              setIsProfileMenuOpen(false);
            }}
          ></ProfileMenuItem>

          <ProfileMenuItem
            text={"New room"}
            src={"/images/plus.svg"}
            onClick={() => {
              handleCreateRoom();
              setIsProfileMenuOpen(false);
            }}
          ></ProfileMenuItem>
          <ProfileMenuItem
            text={"Feedback"}
            src={"/images/feedback.svg"}
            onClick={() => {}}
          ></ProfileMenuItem>
          {location !== "/" && (
            <ProfileMenuItem
              text={"Return home"}
              src={"/images/return-home.svg"}
              onClick={() => {
                setLocation("/");
                setIsProfileMenuOpen(false);
              }}
            ></ProfileMenuItem>
          )}
          <ProfileMenuItem
            text={"Guidelines"}
            src={"/images/more-menu/blog.svg"}
            onClick={() => {
              window.open(
                "https://help.moonlight.world/article/52-moonlight-community-guidelines",
                "_blank"
              );
            }}
          ></ProfileMenuItem>
          <ProfileMenuItem
            text={"About"}
            src={"/images/about.svg"}
            onClick={() => {
              window.open("https://www.moonlight.world", "_blank");
            }}
          ></ProfileMenuItem>
          <StyledPlusMenuItem
            className="plus-menu-item"
            isOnPlusPlan={isOnPlusPlan}
            onClick={() => {
              // if on homepage, open in the same tab
              if (window.location.pathname === "/") {
                window.location.href = "/plans";
                return;
              }
              window.open(
                window.location.protocol +
                  "//" +
                  window.location.host +
                  "/plans",
                "_blank"
              );
            }}
          >
            {isOnPlusPlan ? "PLUS PLAN" : "GET PLUS"}
          </StyledPlusMenuItem>
          <div
            style={{
              width: "calc(100% + 16px)",
              marginLeft: "-8px",
              marginTop: "12px",
              marginBottom: "8px",
              height: "1px",
              backgroundColor: "#D2D2D2",
            }}
          />
          <ProfileMenuItem
            text={"Sign out"}
            src={"/images/signout.svg"}
            onClick={() => {
              signout();
              setLocation("/signin");
              setIsProfileMenuOpen(false);
            }}
          ></ProfileMenuItem>
        </div>
      )}
    </div>
  );
  return (
    <StyledTopBar darkMode={inDarkMode} isInRoom={isInRoom}>
      <div
        className="logo-container"
        style={{ display: "flex", alignItems: "center" }}
      >
        <img
          className="moonlight-logo"
          src={imageUrl("/moonlight_logo(black).png")}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (isInRoom || isConnecting) {
              if (isOnMobile) return;

              window.open(
                window.location.protocol + "//" + window.location.host,
                "_blank"
              );
            } else {
              // if was already /, reload
              if (window.location.pathname === "/") {
                window.location.reload();
              } else {
                setLocation("/");
              }
            }
          }}
        />
        <span id="new-place" style={{ fontSize: "12px", marginLeft: "14px" }}>
          A new place for tarot
        </span>
      </div>

      <div className="right-container">
        {user ? (
          profileMenu
        ) : (
          <>
            {!window.location.pathname.includes("profile") && (
              <Button
                id="cart-button"
                style={{
                  height: "26px",
                  padding: "0",
                  width: "72px",
                  fontSize: "12px",
                  borderRadius: "9px",
                  margin: 0,
                  marginRight: "4px",
                  border: !inDarkMode
                    ? "1px solid black"
                    : "1px solid transparent",
                }}
                animateIn
                onClick={() => {
                  localState.cartPage = CartPage.CART;
                }}
              >
                <img src="/images/cart.svg" />
                {currCartIds.length > 0 ? `${currCartIds.length}` : ""}
              </Button>
            )}
            <Button
              id="create-room-button"
              style={{
                width: 0,
                height: 0,
                pointerEvents: "none",
                opacity: 0,
                padding: 0,
                margin: 0,
                marginLeft: "-2px",
              }}
              onClick={() => {
                handleCreateRoom();
                setIsProfileMenuOpen(false);
              }}
            >
              + Room
            </Button>
            <Button
              id="create-room-with-question-button"
              style={{
                height: "0px",
                width: "0px",
                pointerEvents: "none",
                padding: "0px",
                opacity: 0,
              }}
              onClick={async () => {
                trackEvent("/topbar/create_room_with_question");
                await handleCreateRoom("?useQuestion=true");
                setIsProfileMenuOpen(false);
              }}
            >
              + Room
            </Button>
            <Button
              style={{
                height: "26px",
                padding: "0",
                width: "72px",
                fontSize: "12px",
                borderRadius: "9px",
                marginRight: "4px",
              }}
              animateIn
              onClick={() => {
                trackEvent("/topbar/log_in");
                window.location.href = "/signin";
              }}
            >
              Log in
            </Button>
            <Button
              animateIn
              id="sign-up-button"
              style={{
                height: "26px",
                padding: "0",
                width: "72px",
                fontSize: "12px",
                borderRadius: "9px",
                marginRight: "16px",
                backgroundColor: "black",
                color: "white",
              }}
              onClick={() => {
                trackEvent("/topbar/sign_up");
                window.location.href = "/signup";
              }}
            >
              Sign up
            </Button>
          </>
        )}
      </div>
    </StyledTopBar>
  );
};

const StyledLinkInput = styled("input", {
  "&:focus": {
    outline: "none",
  },
});

const StyledPlusMenuItem = styled("div", {
  backgroundColor: "$lightPurple",
  fontSize: "11px",
  fontStyle: "italic",
  padding: "6px",
  borderRadius: "50px",
  textAlign: "center",
  maxWidth: "calc(100% - 16px)",
  marginLeft: "8px",
  marginTop: "4px",
  cursor: "pointer",
  transition: "background-color 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "$lightPurpleHover",
  },
  variants: {
    isOnPlusPlan: {
      true: {
        backgroundColor: "$gray100",

        "&:hover": {
          backgroundColor: "$gray200",
        },
      },
      false: {},
    },
  },
});

const StyledProfileMenuItem = styled("div", {
  fontSize: "12px",
  padding: "8px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "$gray100",
    borderRadius: "12px",
  },
  "& .icon": {
    width: "20px",
    height: "20px",
    margin: 0,
    marginRight: "10px",
  },
});
const fadein = keyframes({
  "0%": { opacity: 0 },
  "50%": { opacity: 0 },
  "100%": { opacity: 1 },
});
const StyledTopBar = styled("div", {
  width: "100%",
  height: "$navHeight",
  backgroundColor: "$washA500",
  zIndex: "1001",
  borderBottom: "1px solid rgba(0,0,0,0.5)",
  position: "fixed",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  "& img": {
    width: "117px",
    marginLeft: "11px",
  },
  "& .profile-pic": {
    marginRight: "12px",
    marginTop: "0",
    cursor: "pointer",
    animation: `${fadein} 1s ease-in-out`,
  },
  "& .profile-menu": {
    position: "absolute",
    width: "150px",
    right: "12px",
    top: "calc($navHeight + 16px)",
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "16px",
    padding: "26px 8px 16px 8px ",

    "& .menu-item": {
      fontSize: "12px",
      padding: "8px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "$gray100",
        borderRadius: "12px",
      },
    },
  },

  "& #cart-button": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "54px !important",
    "& img": {
      width: "20px",
      marginLeft: "1px",
      marginRight: "1px",
    },
  },
  "@mobile": {
    height: "50px",
  },

  "& .logo-container": {
    "@mobile": {
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginLeft: "-4px",
      },
    },
  },
  "& .right-container": {
    display: "flex",
    "& button": {
      textWrap: "nowrap",
    },
  },
  variants: {
    darkMode: {
      true: {
        backgroundColor: "$darkwashA500",
        borderBottom: "1px solid rgba(255, 255, 255,0.5)",
        "& .moonlight-logo": {
          filter: "invert(1)",
        },
        "& #new-place": {
          color: "white",
        },
      },
      false: {},
    },
    isInRoom: {
      true: {
        // borderBottom: "none",
        // pointerEvents: "none",
        // "& button": {
        //   pointerEvents: "all",
        // },
        // "& .profile-pic": {
        //   pointerEvents: "all",
        // },
      },
    },
  },
  "& .custom-button": {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
});
function updateRoomList() {
  throw new Error("Function not implemented.");
}
