import { keyframes, styled } from "~/ui/style/stitches.config";
import {
  AnimatePresence,
  motion,
  useDeprecatedInvertedScale,
} from "framer-motion";
import { JSXElementConstructor, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { useMutateStorage, useStorage } from "~/state/liveblocks.config";
import { RoomState, SpreadCard, videoDeckIds } from "~/types/room/types";
import { localState } from "~/state/state";

import { Button } from "../components/Button";
import { shallow } from "@liveblocks/client";
import { HelpButtonSmall } from "../components/HelpButton";
import { ReadingNotes } from "./ReadingNotes";
import { QuestionHover } from "../components/Tooltip";
import { imageUrl } from "~/utils/imageurl";

const dots = (
  <svg
    id="dots"
    width="132px"
    height="58px"
    viewBox="0 0 132 58"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>dots</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" fill="none">
      <g id="dots" fill="black">
        <circle id="dot1" className="dot" cx="25" cy="30" r="13"></circle>
        <circle id="dot2" className="dot" cx="65" cy="30" r="13"></circle>
        <circle id="dot3" className="dot" cx="105" cy="30" r="13"></circle>
      </g>
    </g>
  </svg>
);

export const Guidebook = ({ isReadingNotes }: { isReadingNotes?: boolean }) => {
  const state = useStorage((root) => root.state);
  const theme = useStorage((root) => root.theme);
  const deck = useStorage((root) => root.deck, shallow);
  const cardIds = useStorage(
    (root) => root.cards.map((card) => card.filePath),
    shallow
  );
  const updateStorage = useMutateStorage();

  const reversesOn = useStorage((root) => root.reversesOn);
  const [prevLastViewedCard, setPrevLastViewedCard] =
    useState<SpreadCard | null>(null);
  const [currContent, setCurrContent] = useState<string | JSX.Element>("");
  const [updatedCurrContent, setUpdatedCurrContent] = useState<
    string | JSX.Element
  >("");
  const { isGuidebookOpen, isNotebookOpen, isOnMobile, lastViewedCard } =
    useSnapshot(localState);
  const [showDots, setShowDots] = useState(false);
  const [isCardReversed, setIsCardReversed] = useState(false);
  const [cardThumbnail, setCardThumbnail] = useState<string | null>(null);
  const [fadePage, setFadePage] = useState(false);
  const question = useStorage((root) => root.question);
  const notes = useStorage((root) => root.notes);

  useEffect(() => {
    if (fadePage)
      window.setTimeout(() => {
        setFadePage(false);
        setUpdatedCurrContent(currContent);
      }, 400);
  }, [fadePage, currContent]);

  const guidebookPages = {
    ready: (
      <StyledTextPage>
        <div className="title">Welcome to Moonlight!</div>

        <div className="text-section">
          Invite anyone you’d like to join and explore tarot together.
        </div>
        <div className="text-section">
          Your guidebook is here as a simple reference.{" "}
        </div>
      </StyledTextPage>
    ),
    shuffle: (
      <StyledTextPage>
        <div className="title">Does a question float to mind?</div>
        <div className="text-section">
          Could be big or small. Dreams, everyday things, or themes you’re
          curious to explore.
        </div>
      </StyledTextPage>
    ),
    table: (
      <StyledTextPage>
        <div className="title">Explore the cards.</div>
        <div className="text-section">
          Drag cards anywhere and tap to flip. Tap each card again to read about
          them in your guidebook.
        </div>
        <div className="text-section">
          You can customize room settings with the toolbar icons.
        </div>
      </StyledTextPage>
    ),
    notes: (
      <StyledTextPage>
        <div className="title">Write notes together</div>
        <div className="text-area-container">
          <textarea
            className="question"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              updateStorage((storage) => {
                storage.set("question", e.target.value);
              });
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            placeholder={"Your guiding question"}
            maxLength={100}
            spellCheck={false}
            required
          />
          <div id="question-hover" className="question-hover">
            <QuestionHover
              text={
                "<div><b>Example question</b><br/><br/>What should my weekend persona be?<br/>"
              }
              menuStyle={{
                width: "110px",
                textWrap: "wrap",
                textAlign: "left",
              }}
            />
          </div>
        </div>
        <div className="text-area-container">
          <textarea
            className="reflections"
            onChange={(e) => {
              updateStorage((storage) => {
                storage.set("notes", e.target.value);
              });
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            placeholder="Your reflections"
            maxLength={250}
            required
            spellCheck={false}
          />
          <div id="reflections-hover" className="question-hover">
            <QuestionHover
              text={
                "<div>Note anything that comes to mind.<br/><br/>What are your biggest takeaways? Did any illustrations jump out at you?<br/>"
              }
              menuStyle={{
                width: "110px",
                textWrap: "wrap",
                textAlign: "left",
              }}
            />
          </div>
        </div>
        <img
          style={{ position: "absolute", right: "10px" }}
          className="download-button"
          src={"/images/download-arrow.svg"}
          alt="download"
          onClick={(e) => {
            document.getElementById("download-button")?.click();
            e.stopPropagation();
          }}
        />
      </StyledTextPage>
    ),
  };

  const numPages = 6;
  const pagePeekSize = 2;
  const pages = [];

  useEffect(() => {
    if (lastViewedCard?.filePath.includes("mp4")) setCardThumbnail(null);
    else if (showDots)
      setCardThumbnail(
        lastViewedCard?.filePath ? lastViewedCard?.filePath : null
      );
    setIsCardReversed(lastViewedCard?.reversed && reversesOn ? true : false);
    if (showDots) window.setTimeout(() => setShowDots(false), 1000);
  }, [showDots]);

  useEffect(() => {
    if (cardIds.length === 0) localState.lastViewedCard = null;
  }, [cardIds]);

  useEffect(() => {
    if (lastViewedCard?.reversed) setFadePage(true);
  }, [reversesOn]);

  useEffect(() => {
    setCurrContent(guidebookPages.notes);
    setFadePage(true);
  }, [isReadingNotes]);

  useEffect(() => {
    if (isReadingNotes) {
      return;
    } else if (state === RoomState.Ready) {
      setFadePage(true);
      setCurrContent(guidebookPages.ready);
    } else if (state === RoomState.Shuffle) {
      setFadePage(true);
      setCurrContent(guidebookPages.shuffle);
    } else if (state === RoomState.Draw) {
      if (lastViewedCard) {
        const currCardData = deck.cards.find(
          (card) => card.filePath === lastViewedCard.filePath
        );
        if (prevLastViewedCard?.filePath !== lastViewedCard.filePath) {
          setFadePage(true);
        }
        setPrevLastViewedCard(lastViewedCard as SpreadCard);

        const isReversed = lastViewedCard.reversed && reversesOn;
        const keywords =
          isReversed &&
          currCardData?.keywordsReverse &&
          currCardData?.keywordsReverse.length > 0 &&
          currCardData?.keywordsReverse[0].length > 0
            ? currCardData?.keywordsReverse
            : currCardData?.keywords;

        const descriptionText =
          isReversed && currCardData?.descriptionReverse
            ? currCardData?.descriptionReverse
            : currCardData?.description;
        const content = (
          <StyledCardContent Theme={theme}>
            <div className="left-text">
              <div className="title">{`${currCardData?.name}${
                isReversed ? " (R)" : ""
              }`}</div>
              <div className="deck-title">{deck.name}</div>
            </div>
            <img className="quote" src="/images/quote.svg"></img>
            <div className="info-box-container">
              <div
                className="info-box"
                onTouchMove={(e) => {
                  e.stopPropagation();
                }}
              >
                {keywords?.length && keywords.length > 0 ? (
                  <div className="quote-text keywords">
                    {keywords?.join(" • ").toLowerCase()}
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="quote-text description"
                  dangerouslySetInnerHTML={{
                    __html: descriptionText ? descriptionText : "",
                  }}
                ></div>
              </div>
            </div>

            {!videoDeckIds.includes(deck.id) &&
              currCardData?.filePath &&
              !currCardData.filePath.includes(".mp4") && (
                <img
                  style={{
                    transform: `rotate(${isReversed ? "180deg" : "0"})`,
                  }}
                  className="thumbnail"
                  src={imageUrl(currCardData.filePath, 120)}
                />
              )}
          </StyledCardContent>
        );
        setCurrContent(content);
      } else {
        setCurrContent(guidebookPages.table);
        setFadePage(true);
      }
    }
    setShowDots(true);
  }, [state, lastViewedCard, reversesOn]);

  for (let i = 0; i < numPages; i++) {
    const newPage = (
      <div
        key={i}
        className="contents page"
        style={{
          left: -pagePeekSize * i + "px",
        }}
      >
        <div className="book-line"></div>
        <div className="contents-text">
          <div className="text">{updatedCurrContent}</div>
        </div>
      </div>
    );
    pages.push(newPage);
  }
  return (
    <>
      <StyledGuidebook
        style={{ pointerEvents: "auto" }}
        Theme={theme}
        className={`open-${isReadingNotes ? isNotebookOpen : isGuidebookOpen}`}
        onClick={() => {
          if (isReadingNotes) localState.isNotebookOpen = !isNotebookOpen;
          else localState.isGuidebookOpen = !isGuidebookOpen;
        }}
      >
        <div className={`page-container fade-page-${fadePage}`}>
          {pages}
          <div className="cover">
            <div className=" page">
              <img
                className="icon open-icon"
                src="/images/room/menu-close.png"
              />

              <div className="cover-text">
                {isReadingNotes ? "Notebook" : "Guidebook"}
                <span className="hotkey">{isReadingNotes ? "N" : "G"}</span>
              </div>
              {showDots && dots}
              {!showDots && (
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      style={{
                        transform: `rotate(${isCardReversed ? "180deg" : "0"})`,
                      }}
                      className={`preview-img icon ${
                        cardThumbnail ? "" : "info-icon"
                      }`}
                      src={
                        cardThumbnail
                          ? imageUrl(cardThumbnail, 40)
                          : "/images/info-icon.svg"
                      }
                    ></img>
                  </motion.div>
                </AnimatePresence>
              )}
            </div>
          </div>
        </div>
      </StyledGuidebook>
      {isOnMobile && (
        <div
          style={{
            position: "absolute",
            left: "3px",
            top: "calc(50% + 100px)",
            bottom: 0,
            margin: "auto",
            zIndex: 3,
          }}
          className="help-button"
        >
          <HelpButtonSmall />
        </div>
      )}
    </>
  );
};

const load = keyframes({
  "0%": { opacity: 0 },
  "50%": { opacity: 1 },
  "100%": { opacity: 0 },
});

export const StyledTextPage = styled(motion.div, {
  padding: "5px",
  fontSize: "10px",
  "@mobile": {
    fontSize: "12px",
  },
  "& .title": {
    fontSize: "19px",
    lineHeight: "1.0",
    marginBottom: "10px",
    fontFamily: "KeplerLightCondensed",
    "@mobile": {
      fontSize: "22px",
    },
  },
  "& .text-section": {
    marginBottom: "10px",
  },
  "& .bold-text": {
    fontWeight: "bold",
  },
  "& .italic-text": {
    fontStyle: "italic",
  },

  "& textarea": {
    width: "calc(100% + 8px)",
    marginTop: "4px",
    padding: "6px",
    borderRadius: "5px",
    border: "none",

    resize: "none",
    marginLeft: "-6px",
    fontSize: "10px",
    "&:focus": {
      border: "1px solid rgba(0, 0, 0, 0.4)",
      outline: "none",
    },
    "&.question": {
      display: "flex",
      height: "40px",
    },
    "&.reflections": {
      height: "116px",
    },
  },
});

const StyledCardContent = styled(motion.div, {
  "& .thumbnail": {
    maxHeight: "74px",
    maxWidth: "40px",
    width: "auto",
    position: "absolute",
    right: "8px",
    top: "8px",
    borderRadius: "4px",
    border: "0.2px solid rgba(0, 0, 0, 0.4)",
  },
  "& .left-text": {
    width: "90px",
    marginTop: "-1px",
  },
  "& .title": {
    textTransform: "uppercase",
    fontSize: "12px",
    "@mobile": {
      fontSize: "13px",
    },
    fontWeight: "500",
    marginBottom: "6px",
  },
  "& .deck-title": {
    fontSize: "9px",
    "@mobile": {
      fontSize: "10px",
    },
  },
  "& .quote": {
    width: "13px",
    position: "absolute",
    left: "41px",
    top: "74px",
    display: "none"
  },
  "& .info-box-container": {
    overflow: "hidden",
    borderRadius: "8px",
    position: "absolute",
    border: "1px solid #e0e0e0",
    width: "140px",
    fontSize: "10px",
    "@mobile": {
      fontSize: "12px",
    },
    top: "90px",
    height: "107px",
  },

  "& .info-box": {
    overflow: "auto",
    height: "calc(100% +  2px)",
    marginTop: "-1px",
    marginRight: "-1px",
    padding: "8px",
    // fontStyle: "italic",

    "& .keywords": {
      marginBottom: "8px",
    },
    "& .description": {
      //   fontStyle: "italic",
    },

    "&::-webkit-scrollbar": {
      width: "10px",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      background: "rgba(200, 200, 200, 0.25)",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      border: "1px solid #dcdcdc",
      background: "rgba(160, 160, 160, 0.9)",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {},
  },
  "& .disclaimer": {
    fontSize: "9.5px",
    fontStyle: "italic",
    bottom: "9px",
    width: "140px",
    position: "absolute",
    fontWeight: "300",
  },
  transition: "opacity 0.2s",
  opacity: 1,
  variants: {
    Theme: {
      dark: {},

      light: {},
    },
    transitioning: {
      true: {
        opacity: 0,
      },
      false: {},
    },
  },
});

const StyledGuidebook = styled("nav", {
  left: 0,
  top: 0,
  bottom: 0,
  cursor: "pointer",
  zIndex: "2",
  position: "relative",
  "@mobile": {
    top: "-20px",
  },
  "& .share-info": {
    zIndex: "100",
    bottom: "6px",
    position: "absolute",
    fontSize: "9px",
    fontStyle: "italic",
    left: "26px",
    display: "flex",
    alignItems: "center",
    gap: "3px",
  },
  transition: "all 0.3s cubic-bezier(0.65, 0, 0.35, 1)",
  "&.open-false": {
    transform: "translateX(-157px)",
  },
  "& .page-container": {
    position: "relative",
  },
  "& .fade-page-true .contents-text": {
    opacity: 0,
  },
  "& .book-line": {
    width: "1px",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    height: "238px",
    marginLeft: "27px",
    position: "absolute",
    boxShadow: "-2px 0px 8px 0px rgba(0,0,0,0.5)",
  },
  "& .contents-text": {
    transition: "opacity 0.4s cubic-bezier(0.65, 0, 0.35, 1)",
    width: "138px",
    marginLeft: "40px",
    marginTop: "16px",
    fontSize: "12px",
  },
  "& .page-bookmark": {
    width: "16px",
    position: "absolute",
    left: "10px",
    top: "-4px",
    display: "none",
  },
  "& .page": {
    width: "190px",
    height: "240px",
    backgroundColor: "white",
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: "12px",
  },
  "& .page.contents": {
    border: "1px solid rgba(0, 0, 0, 0.2)",
  },
  "& .cover": {
    position: "relative",
    transition: "all 0.3s ease-in-out",

    "& .bookmark": {
      position: "absolute",
      display: "none",
      top: "20px",
      right: "-10px",
      width: "20px",
      transform: "rotate(-90deg)",
    },
    "& .page": {
      position: "relative",
      backgroundColor: "$wash",
      border: "1px solid $darkwashA500",
      color: "black",
    },
    "& .cover-text": {
      position: "absolute",
      right: "9px",
      top: "180px",

      fontFamily: "KeplerLightSemicondensed",
      fontSize: "16px",
      "@mobile": {
        fontSize: "20px",
        right: "7px",
        top: "188px",
      },
      transform: "rotateZ(90deg)",
      transformOrigin: "top right",
      textTransform: "uppercase",
      display: "flex",
      alignItems: "center",
      "& .hotkey": {
        fontFamily: "WorkSans",
        fontWeight: "200",
        fontSize: "11px",
        height: "18px",
        marginLeft: "12px",
        marginTop: "-4px",
        padding: "3px 5px",
        borderRadius: "4px",
        backgroundColor: "$gray200",
        transform: "rotate(-90deg)",
        "@mobile": {
          opacity: 0,
        },
      },
    },
    "& .icon": {
      position: "absolute",
      right: "7px",
      bottom: "12px",
      width: "18px",
    },
    "& .preview-img": {
      borderRadius: "2px",
    },

    "& .open-icon": {
      top: "12px",
      width: "21px",
      right: "6px",
    },
  },

  "& .text-area-container": {
    position: "relative",
    "& .question-hover": {
      position: "absolute",
      top: "6px",
      right: "2px",
      "&#reflections-hover": {
        top: "8px",
        right: "28px",
      },
    },
    // check if textarea isn't empty
    "& textarea:valid + .question-hover, & textarea:focus + .question-hover": {
      display: "none",
    },
  },

  "&.open-true .cover": {
    transform: "translateX(-250px)",
  },
  "& #dots": {
    position: "absolute",
    right: "8px",
    bottom: "22px",
    transform: "scale(0.35)",
    transformOrigin: "bottom right",
  },
  "& #dots #dot1": {
    animation: `${load} 1s`,
    animationIterationCount: "1",

    opacity: 0,
  },

  "& #dots #dot2": {
    animation: `${load} 1s`,
    animationDelay: "0.2s",
    animationIterationCount: "1",
    opacity: 0,
  },

  "& #dots #dot3": {
    animation: `${load} 1s`,
    animationDelay: "0.4s",
    animationIterationCount: "1",
    opacity: 0,
  },
  variants: {
    Theme: {
      dark: {
        "& #dots": {
          fill: "white",
        },
        "& .page": {
          backgroundColor: "$darkwash",
          border: "1px solid $washA500 !important",
        },
        "& .book-line": {
          backgroundColor: "#c0c0c0",
        },

        "& .cover": {
          "& .page": {
            backgroundColor: "$darkwash",
            border: "1px solid #c0c0c0",
            color: "white",
          },
          "& .open-icon": {
            filter: "invert(1)",
          },
          "& .info-icon": {
            filter: "invert(1)",
          },
        },
        "& .hotkey": {
          color: "white",
          backgroundColor: "black !important",
        },
      },
      light: {},
    },
  },
});
