export const CARD_SPREAD_SIZE = [300, 527];
export const CARD_BASE_RADIUS = 30;
export const CARD_HAND_WIDTH = 100;
export const CARD_SHUFFLE_SIZE = [150, (150 * 527) / 300];

export const ZOOM_BASE = 1.2;
export const DEFAULT_ZOOM_INDEX = -1;
export const MOBILE_ZOOM_INDEX_MOD = -5;
export const MIN_ZOOM_INDEX = -9;
export const MAX_ZOOM_INDEX = 3;
export const PRELOAD_TIMEOUT_MS = 15000;

export const MAX_ROOM_ATTENDEES = 6;

export const DECK_PRICE = 12;

export const BIO_CHAR_LIMIT = 300;
export const DISPLAY_NAME_CHAR_LIMIT = 24;
export const BOOKING_MODULE_CHAR_LIMIT = 50;
export const TAGLINE_CHAR_LIMIT = 34;

export const MOONLIGHT_SESSION_FEE_PERCENTAGE = 0.15

export const PAN_BASE = 40;

export const LOCAL_STORAGE_USER = "MoonlightUser";

export const PASSWORD_VALIDATION_REGEX = ".{6,}$"

//eslint-disable-line
export const URL_VALIDATION_REGEX = `^[^*]+\\.[^*]+$` 

export const TOAST_TIME_MS = 3000;


//TODO: this is all really hacky. each spread should have a zoom index default in the spread data
// just a workaround for me to be able to set the zoom index for each spread
export const SPREAD_HEIGHT_TO_ZOOM_INDEX = (spreadHeight: number) => {
    if (Math.floor(spreadHeight) > 3) {
        return -7
    } else if (spreadHeight > 1.5) {
        return -5
}  else if (spreadHeight > 1.1) {
    return -4
}
    else if (spreadHeight > 1.07) {
        return -3
}
    else if (spreadHeight > 1.05) {
    return 0
}
else if (Math.floor(spreadHeight) > 1) {
        return -3
} else {
        return DEFAULT_ZOOM_INDEX
    }
}


