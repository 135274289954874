import { Deck, Spread, SpreadCard } from "~/types/room/types";
import defaultSpread from "../assets/spreads/persona-obstacle-solution.json";
import { proxy } from "valtio";
import { DEFAULT_ZOOM_INDEX } from "~/utils/consts";

export enum CartPage {
  DECK = "DECK",
  CART = "CART",
  CHECKOUT = "CHECKOUT",
  CLOSED = "CLOSED",
  SIGNUP = "SIGNUP",
  SUCCESS= "SUCCESS",
}



export const localState = proxy<{
    activeCard: string | null;
    lastViewedCard: SpreadCard | null;
    activeSpread: Spread;
    zoomIndex: number;
    isMouseDown: boolean;
    pan: [number, number];
    panScreenDelta: [number, number];
    inDrawingMode: boolean;
    isGuidebookOpen: boolean;
    isNotebookOpen: boolean;
    currentMessage: {
      msg: string | null,
    isError: boolean,
    isPerpetual: boolean
  };
  isFocusedTyping: boolean;
  isOnPlusPlan: boolean;
  isOnMobile: boolean;
    inTypingMode: boolean;
    isInRoom: boolean;
    isConnecting: boolean;
    isConnected: boolean;
    editedProfilePic: boolean;
    inDarkMode: boolean;
    isMobileMessageActive: boolean;
    lockHover: boolean;
    cartPage: CartPage;
    recentCartDeck: Deck | null;
    currCartIds: string[];
    currSessionVideoURL: string | null;
  }>({
    activeCard: null,
    lastViewedCard: null,
    activeSpread: defaultSpread as unknown as Spread,
    zoomIndex: DEFAULT_ZOOM_INDEX,
    isMouseDown: false,
    pan: [0, 0],
    panScreenDelta: [0, 0],
    inDrawingMode: false,
    inTypingMode: false,
    isOnPlusPlan: true,
    isGuidebookOpen: true,
    isNotebookOpen: true,
    isOnMobile: false,
    isFocusedTyping: false,
    currentMessage: {
      msg: null,
      isError: false,
      isPerpetual: false
    },
    isInRoom: false,
    isConnected: false,
    isConnecting: false,
    editedProfilePic: false,
    inDarkMode: false,
    isMobileMessageActive: false,
    lockHover: false,
    cartPage: CartPage.CLOSED,
    recentCartDeck: null,
    currCartIds: [],
    currSessionVideoURL: null
  });

  export function saveCartIds(cartIds: string[]) {
    localStorage.setItem("cartIds", JSON.stringify(cartIds));
    localState.currCartIds = cartIds;
  }
  
  export function addToCartIds(cartId: string) {
    const cartIds = loadCartIds();
    cartIds.push(cartId);
    localState.currCartIds = cartIds;
    saveCartIds(cartIds);
  }
  
  export function removeFromCartIds(cartId: string) {
    const cartIds = loadCartIds();
    const newCartIds = cartIds.filter((id: string) => id !== cartId);
    localState.currCartIds = newCartIds;
    saveCartIds(newCartIds);
  }
  
  export function loadCartIds() {
    const cartIds = localStorage?.getItem("cartIds");
    if (cartIds) {
      localState.currCartIds = JSON.parse(cartIds);
      return JSON.parse(cartIds);
    }
    localState.currCartIds = [];
    return [];
  }
  