import { useEffect, useState } from "react";
import { Button } from "../components/Button";
import { keyframes, styled } from "~/ui/style/stitches.config";
import { useLocation } from "wouter";

import { LoadingIndicator } from "~/pages/loading/LoadingPage";

export const VideoSessionSurvey = ({
  requesterName,
  hostEmail,
  hostUsername,
  requesterEmail,
}: {
  requesterName: string | undefined;
  hostEmail: string | undefined;
  hostUsername: string | undefined;
  requesterEmail: string | undefined;
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `var d=document,w="https://tally.so/widgets/embed.js",v=function(){"undefined"!=typeof Tally?Tally.loadEmbeds():d.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((function(e){e.src=e.dataset.tallySrc}))};if("undefined"!=typeof Tally)v();else if(d.querySelector('script[src="'+w+'"]')==null){var s=d.createElement("script");s.src=w,s.onload=v,s.onerror=v,d.body.appendChild(s);}`;
    document.body.appendChild(script);
  }, []);
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<iframe data-tally-src="https://tally.so/embed/mRJbyd?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&host_email=${hostEmail}&requester_name=${requesterName}&requester_email=${requesterEmail}&host_username=${hostUsername}" loading="lazy" width="100%" height="231" frameborder="0" marginheight="0" marginwidth="0" title="Async Session Notes"></iframe><script>var d=document,w="https://tally.so/widgets/embed.js",v=function(){"undefined"!=typeof Tally?Tally.loadEmbeds():d.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((function(e){e.src=e.dataset.tallySrc}))};if("undefined"!=typeof Tally)v();else if(d.querySelector('script[src="'+w+'"]')==null){var s=d.createElement("script");s.src=w,s.onload=v,s.onerror=v,d.body.appendChild(s);}</script>`,
      }}
    />
  );
};

export const Survey = ({
  email,
  username,
  dest,
  displayName,
}: {
  email: string | undefined;
  username: string | undefined;
  dest: string | null;
  displayName: string | undefined;
}) => {
  console.log(email, username);
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `var d=document,w="https://tally.so/widgets/embed.js",v=function(){"undefined"!=typeof Tally?Tally.loadEmbeds():d.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((function(e){e.src=e.dataset.tallySrc}))};if("undefined"!=typeof Tally)v();else if(d.querySelector('script[src="'+w+'"]')==null){var s=d.createElement("script");s.src=w,s.onload=v,s.onerror=v,d.body.appendChild(s);}`;
    document.body.appendChild(script);
  }, []);
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<iframe data-tally-src="https://tally.so/embed/3jZ7aR?hideTitle=1&transparentBackground=1&dynamicHeight=1&username=${username}&name=${displayName}&email=${email}${
          dest ? `&redirect=${dest}` : ``
        }" loading="lazy" width="100%" height="340" frameborder="0" marginheight="0" marginwidth="0" title="Registration Info"></iframe>`,
      }}
    />
  );
};

export const BookingRegistrationSurvey = ({
  email,
  username,
  price,
  host,
}: {
  email: string | undefined;
  username: string | undefined;
  price: number | undefined;
  host: string | undefined;
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `var d=document,w="https://tally.so/widgets/embed.js",v=function(){"undefined"!=typeof Tally?Tally.loadEmbeds():d.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((function(e){e.src=e.dataset.tallySrc}))};if("undefined"!=typeof Tally)v();else if(d.querySelector('script[src="'+w+'"]')==null){var s=d.createElement("script");s.src=w,s.onload=v,s.onerror=v,d.body.appendChild(s);}`;
    document.body.appendChild(script);

    console.log(email, username, price, host);
  }, []);
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<iframe data-tally-src="https://tally.so/embed/mD70W5?hideTitle=1&transparentBackground=1&dynamicHeight=1&username=${username}&email=${email}&price=${price}&host=${host}" loading="lazy" width="100%" height="340" frameborder="0" marginheight="0" marginwidth="0" title="Registration Info"></iframe>`,
      }}
    />
  );
};

export const TarotPageSurvey = ({
  emailToNotify,
  usernameToNotify,
  tarotProUsername,
  tarotProEmail,
}: {
  emailToNotify: string | undefined;
  usernameToNotify: string | undefined;
  tarotProUsername: string | undefined;
  tarotProEmail: string | undefined;
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `var d=document,w="https://tally.so/widgets/embed.js",v=function(){"undefined"!=typeof Tally?Tally.loadEmbeds():d.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((function(e){e.src=e.dataset.tallySrc}))};if("undefined"!=typeof Tally)v();else if(d.querySelector('script[src="'+w+'"]')==null){var s=d.createElement("script");s.src=w,s.onload=v,s.onerror=v,d.body.appendChild(s);}`;
    document.body.appendChild(script);
  }, [emailToNotify, usernameToNotify, tarotProEmail, tarotProUsername]);
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<iframe data-tally-src="https://tally.so/embed/npr1YE?hideTitle=1&transparentBackground=1&dynamicHeight=1&emailToNotify=${emailToNotify}&usernameToNotify=${usernameToNotify}&tarotProEmail=${tarotProEmail}&tarotProUsername=${tarotProUsername}" loading="lazy" width="100%" height="340" frameborder="0" marginheight="0" marginwidth="0" title="Registration Info"></iframe>`,
      }}
    />
  );
};

export const PostLeavingSurvey = ({
  email,
  username,
  dest,
  name,
}: {
  email: string | undefined;
  username: string | undefined;
  name: string | undefined;
  dest: string;
}) => {
  let [location, setLocation] = useLocation();

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `var d=document,w="https://tally.so/widgets/embed.js",v=function(){"undefined"!=typeof Tally?Tally.loadEmbeds():d.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((function(e){e.src=e.dataset.tallySrc}))};if("undefined"!=typeof Tally)v();else if(d.querySelector('script[src="'+w+'"]')==null){var s=d.createElement("script");s.src=w,s.onload=v,s.onerror=v,d.body.appendChild(s);}`;
    document.body.appendChild(script);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#F6F6F6",
        width: "100vw",
        height: "100%",
        textAlign: "center",
        flexDirection: "column",
        overflow: "scroll",
      }}
    >
      <div
        style={{
          paddingTop: "100px",
          zIndex: "3",
        }}
        dangerouslySetInnerHTML={{
          __html: `<iframe  data-tally-src="https://tally.so/embed/mZ8G8e?hideTitle=1&transparentBackground=1&dynamicHeight=1&username=${username}&email=${email}&name=${name}&redirect=${dest}&userAgent=${navigator.userAgent}" loading="lazy" width="100%" height="340" frameborder="0" marginheight="0" marginwidth="0" title="Post room feedback"></iframe>`,
        }}
      />
    </div>
  );
};

const fadeIn = keyframes({
  "0%": { opacity: 0 },
  "80%": { opacity: 0 },
  "100%": { opacity: 1 },
});
