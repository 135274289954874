import { styled } from "~/ui/style/stitches.config";
import { StyledProfessionalDashboard } from "./marketplace/Availability";
import { AuthContext } from "~/utils/useAuth";
import { UserProfile, getUser } from "~/api/userApi";
import { useEffect, useState, useContext } from "react";
import { getReferralsByReferrer } from "~/api/referralsApi";
import { CopyButton } from "~/ui/components/CopyButton";
import { FavesBar } from "~/ui/menus/FavesBar";
import {
  LoadingIndicator,
  SimpleLoadingIndicator,
} from "./loading/LoadingPage";
import { trackEvent } from "~/api/analyticsApi";

export const Referrals = () => {
  const { user } = useContext(AuthContext);
  const [referredUsers, setReferredUsers] = useState<UserProfile[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    trackEvent("/invites/visited_page");

    (async () => {
      if (!user) return;
      const u = await getUser(user?.uuid);
      const referredUserIds = await getReferralsByReferrer(u?.uuid);
      const refU = await Promise.all(
        referredUserIds.map(async (uuid: string) => {
          return await getUser(uuid);
        })
      );
      setReferredUsers(refU);
      setIsLoading(false);
    })();
  }, [user?.uuid]);

  return (
    <StyledUpcomingPage>
      <div className="column middle">
        <div className="page-title">Spread the magic!</div>
        <div className="perks-container">
          <img className="perks-title" src="/images/magic.jpg" />
          <div className="perks-description">
            We’re a new platform and your recommendation means the world. Share
            your invite code with friends and we mayyyy slip some special perks
            your way 🪄
          </div>
          <CopyButton
            buttonText={user?.username || ""}
            copiedText={"Link copied!"}
            copyContent={
              window.location.protocol +
              "//" +
              window.location.host +
              "?invite=" +
              user?.username
            }
            id={"username-section"}
            trackText={"invites/copied_code"}
          />

          <CopyButton
            buttonText={"Share invite link"}
            copiedText={"Link copied!"}
            copyContent={
              window.location.protocol +
              "//" +
              window.location.host +
              "?invite=" +
              user?.username
            }
            id={"share-invite-button"}
            trackText={"invites/copied_code"}
            style={{
              backgroundColor: "#DAEEA1",
              width: "150px",
              height: "40px",
              boxShadow: "0 3px black",
              border: "1px solid black",
              fontSize: "13px",
            }}
          />
        </div>
        {!isLoading && (
          <div className="faves-title" style={{ marginTop: "30px" }}>
            <b>{referredUsers.length}</b>
            {` friend${referredUsers.length !== 1 ? "s" : ""} joined${
              referredUsers.length > 0 ? "!" : ""
            }`}
          </div>
        )}

        {!isLoading && referredUsers.length > 0 && (
          <FavesBar
            isOpen={true}
            setIsOpen={() => {}}
            referredUsers={referredUsers}
          />
        )}
        {isLoading && (
          <div
            style={{
              position: "relative",
              marginTop: "60px",
              marginLeft: "-36px",
            }}
          >
            <SimpleLoadingIndicator />
          </div>
        )}
      </div>
    </StyledUpcomingPage>
  );
};

export const StyledUpcomingPage = styled(StyledProfessionalDashboard, {
  marginTop: "10px",
  overflow: "scroll",
  "& .column.middle": {
    overflow: "hidden",
    height: "fit-content",
  },
  "& .page-subtitle": {
    fontSize: "24px",
    fontWeight: "400",
    marginTop: "10px",
    fontFamily: "KeplerLightCondensed",
    "@mobile": {
      fontSize: "26px",
      lineHeight: "25px",
    },
  },
  "& .sub-subtitle": {
    fontSize: "12px",
    fontWeight: "normal",
    textTransform: "uppercase",
    marginTop: "14px",
  },
  "& .session-container": {
    width: "100%",
    marginTop: "24px",
    paddingBottom: "160px",
  },
  "& .perks-container": {
    width: "400px",
    backgroundColor: "#EBEBEB",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    marginTop: "30px",
    borderRadius: "28px",
    border: "0.5px solid black",
    boxShadow: "0 3px black",
    "& img": {
      borderRadius: "13px",
      width: "214px",
    },
    "& .perks-title": {
      fontWeight: "500",
      fontStyle: "italic",
      fontSize: "18px",
      marginTop: "10px",
    },
    "& .perks-description": {
      marginTop: "15px",
      fontSize: "12px",
      lineHeight: "1.3em",
      textAlign: "center",
      marginBottom: "20px",
    },
    "@mobile": {
      width: "calc(100%)",
    },
  },
  "#username-section": {
    width: "250px",
    height: "44px",
    backgroundColor: "white",
    border: "1px solid #bbb",
    color: "$gray600",
    borderRadius: "60px",
    fontSize: "11px",
    textTransform: "uppercase",
    marginBottom: "18px",
    "& img": {
      display: "none",
    },
  },
  "#share-invite-button": {
    "& img": {
      display: "none",
    },
    "&:hover": {
      boxShadow: "0 0 black !important",
      transform: "translateY(3px)",
    },
  },
  "@mobile": {
    marginTop: "-20px",
    "& .session-container": {
      marginTop: "40px",
    },
    "& #tz-menu": {
      marginTop: "24px !important",
    },
    "& .sub-subtitle": {
      textAlign: "center",
    },
  },
});
